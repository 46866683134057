import React, { useState, useEffect } from 'react';
import { Box, Button, Container, CssBaseline, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { firebaseConfigDev } from './firebase/config';

firebase.initializeApp(firebaseConfigDev);
const useEmulator = !!process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST;
if (useEmulator) {
    firebase.auth().useEmulator(`http://${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST}`);
}

const App = () => {
    const [type, setType] = useState('deadline');
    const [customerType, setCustomerType] = useState('standard');
    const [keys, setKeys] = useState('');
    const [user, setUser] = useState();
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');

    useEffect(() => {
        const unregisterAutoObserver = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setUser(user);
            }
        });
        return () => {
            unregisterAutoObserver();
        }
    }, []);

    useEffect(() => {
        if (type === 'deadline') {
            const nextYear = new Date().getFullYear() + 1;
            setYear(nextYear);
            setMonth(3);
            setDay(31);
        } else if (type === 'period') {
            setYear(1);
            setMonth(0);
            setDay(0);
        } else {
            setYear('');
            setMonth('');
            setDay('');
        }
    }, [type]);

    if (!user) {
        const onSubmit = (e) => {
            e.preventDefault();
            const email = e.target.email.value;
            const password = e.target.password.value;
            firebase.auth().signInWithEmailAndPassword(email, password).catch(() => {
                alert('ログインに失敗しました。');
            });
        };
        return (
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box component="form" onSubmit={onSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="メールアドレス"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="パスワード"
                                type="password"
                                name="password"
                                autoComplete="password"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        ログイン
                    </Button>
                </Box>
            </Container>
        )
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (type === 'period' && year > 1000) {
            setKeys('1000年以上は設定できません。');
            return;
        }
        if (type === 'deadline' && year < new Date().getFullYear() - 1000) {
            setKeys('1000年以上前は設定できません。');
            return;
        }

        const params = new FormData();
        params.append('place', e.target.place.value);
        params.append('type', e.target.type.value);
        params.append('customer_type', e.target.customer_type.value);
        params.append('year', year ? year : '0');
        params.append('month', month ? month : '0');
        params.append('day', day ? day : '0');
        params.append('num', e.target.num.value);
        params.append('token', user.multiFactor.user.accessToken);
        params.append('purchasing_officer_email', e.target.purchasing_officer_email.value);

        axios.post(
            process.env.REACT_APP_UTIL_BACKEND_URL, params
        ).then((response) => {
            if (response.status !== 200) {
                setKeys('予期せぬエラーが発生しました。');
            } else if ('autherror' in response.data) {
                setKeys('認証に失敗しました。');
            } else if ('valuerror' in response.data) {
                setKeys('予期せぬエラーが発生しました。');
            } else if ('error' in response.data) {
                setKeys('予期せぬエラーが発生しました。');
            } else {
                setKeys(response.data['keys']);
            }
        });
    }

    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <Box component="form" onSubmit={onSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {useEmulator ?
                            <FormControl>
                                <FormLabel>
                                    配置先：ローカル環境 (Firebase Emulator)
                                </FormLabel>
                                <RadioGroup name="place" defaultValue="dev">
                                    <FormControlLabel value="dev" control={<Radio />} label="ローカル環境" />
                                </RadioGroup>
                            </FormControl>
                            :
                            <FormControl>
                                <FormLabel>
                                    配置先：テスト環境のコードはテストサーバーに配置されるので、readable.jp では使えません。
                                </FormLabel>
                                <RadioGroup name="place" defaultValue="prod">
                                    <FormControlLabel value="prod" control={<Radio />} label="本番環境" />
                                    <FormControlLabel value="dev" control={<Radio />} label="テスト環境" />
                                </RadioGroup>
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel>
                                会員種別
                            </FormLabel>
                            <RadioGroup name="customer_type" defaultValue="standard" onChange={(e) => setCustomerType(e.target.value)}>
                                <FormControlLabel value="standard" control={<Radio />} label="一般" />
                                <FormControlLabel value="premium" control={<Radio />} label="プレミアム" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel>
                                種類
                            </FormLabel>
                            <RadioGroup name="type" defaultValue="deadline" onChange={(e) => setType(e.target.value)}>
                                <FormControlLabel value="deadline" control={<Radio />} label="いつまで" />
                                <FormControlLabel value="period" control={<Radio />} label="どのくらいの間" />
                                <FormControlLabel value="fiscal" control={<Radio />} label="登録した日からその年度の末まで" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel>
                                {type === 'deadline' && '期限（例: 2025 年 3 月 31 日）'}
                                {type === 'period' && '長さ（例: 1 年 0 月 0 日）'}
                                {type === 'fiscal' && 'この種類の場合は記入なし'}
                            </FormLabel>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField id="year" label="年" variant="outlined" disabled={type === 'fiscal'} value={year} onChange={(e) => setYear(e.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField id="month" label="月" variant="outlined" disabled={type === 'fiscal'} value={month} onChange={(e) => setMonth(e.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField id="day" label="日" variant="outlined" disabled={type === 'fiscal'} value={day} onChange={(e) => setDay(e.target.value)} />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel>
                                個数
                            </FormLabel>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField id="num" variant="outlined" defaultValue="1" inputProps={{ maxLength: 4 }} />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel>
                                注文担当者メールアドレス (任意)
                            </FormLabel>
                            <TextField id="purchasing_officer_email" name="purchasing_officer_email" variant="outlined" type="email" inputMode="email" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit">作成</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="アクティベーションキー"
                            multiline
                            rows={8}
                            variant="outlined"
                            value={keys}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Button variant="text" size="large" sx={{ mt: 2 }} onClick={() => { firebase.auth().signOut(); window.location.reload(); }}>ログアウト</Button>
            </Box>
        </Container>
    )
};

export default App;
